.template_boutique_1{
    .content-products {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25%;
        margin-right: -1.25%;
    }
    .produits {
        flex: 0 0 auto;
        @include elemTailleCol(3 , 1.25, true);
        position:relative;
        font-size:1rem;
        margin-bottom: 15px;
    }
    .productImageWrap{
        position:relative;
        float:none;
        .mask{
            height:100%;
            position:absolute;
            top:0;
            left:0;
        }
    }
    .desc{
        display: none;
    }
    .remise span{
        font-size:12px;
    }
    .productContentWrap{
        text-align: center;
        &::after{
            content:'';
            display: block;
            clear: both;
            height: 1px;
        }
    }
    .addbasket, .hors-stock{
        width:45%;
        padding:6px;
        float:none;
        margin:10px auto;
        height:auto;
        font-size:.7rem;
        display: inline-block;
        box-sizing: border-box;
        position:relative;
        &.next-select{
            margin: 10px 5px 10px 5px;
            float:right;
        }
    }
    .hors-stock{
        box-sizing:border-box;
        font-size: 0;
        display: block;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
    .addbasket{
        text-align: center;
    }
    .prix{
        &::after{
            content:'';
            display:block;
            clear:both;
        }
        .prixprod, .oldprix{
            margin:0 10px 10px;
            display:inline-block;
            position:static;
        }
    }

    .nomprod{
        display:block;
        width:100%;
        font-size: 0;
        a{
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            float:none;
            position:static;
            font-size: .9rem;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .attr{
        float:left;
        width:45%;
        margin:10px 5px 10px 5px;
        position:absolute;
        padding:0;
        select{
            width:100%;
            left:0;
            height:29px;
            margin-top:0;
        }
    }

}

.template_boutique_1 .mode-catalogue .produits .attr{
    position:relative;
    select{
        position:relative;
    }
}
@media #{$medium-and-down} {
    .template_boutique_1 .produits{
        @include elemTailleCol(2 , 1.25, true);
    }
}
@media #{$small-and-down} {
    .template_boutique_1{
        .produits {
            @include elemTailleCol(1 , 1.25, true);
            .zoom{
                display:none;
            }
        }
        .attr{
            float: none;
            clear: both;
            margin:5px auto;
            display: block;
        }
    }
}
