@import '../../../cmonsite/_main_mixin';
@import '../../variables';
@import '../mixin_templates.scss';

.template_boutique{
    //&::after{
    //    content:'';
    //    clear:both;
    //    display:block;
    //}
    .zoom::before{
        color:#fff;
    }
    //.mode-boutique{
    //    &::after, &:before{
    //        content:'';
    //        display: block;
    //        clear: both;
    //    }
    //}
}

.produit_etiquette{
    height: auto;
    width: 100%;
    position: absolute;
}

.template_boutique{
    .boutique_header{
        margin:20px 0;
    }


    .produits{
        font-size:1rem;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 30px;
        padding:0;

        &:hover{
            .zoom{
                opacity:.5;
            }
        }

        .prix__block{
            display: flex;
            align-items: center;
        }
        .prixprod,.oldprix{
            margin-right: 10px;
            white-space: nowrap;
        }
    }
    .block-btn-addbasket{
        margin-left: 20px;
        margin-right: 20px;
    }
    .remise{
        white-space: nowrap;
    }
    .container-etiquette{
        height:auto;
        position: relative;
    }
    .productImageWrap {
        margin-top: 0;
    }
    .mask {
        text-align: center;

        margin: 0;
        width: 100%;
        height: 100%;

        position: absolute;
        z-index: 20;
        left: 0;
        top: 0;

        opacity:0;
    }
    .nomprod{
        box-sizing:border-box;
    }
    .productImageWrap{
        width:100%;
        height: auto;
        img{
            width:100%;
        }
        .zoom{
            width:100%;
            height:100%;
            opacity:0;
            transition:all .2s linear;
            position:absolute;
            display:block;
            top:0;
            left:0;
            z-index:1;
            &::before{
                content: "\f002";
                font-family: fontawesome, sans-serif;
                font-size:80px;
                line-height:80px;
                top:50%;
                left:0;
                right:0;
                position:absolute;
                margin:-40px auto 0;
                display: block;
                text-align:center;
            }
        }
    }
    .fa-shopping-cart{
        margin-right:5px;
    }

    .note-produit {

        &__nb {
            font-size: 0.8em;
        }

    }
}

@import 'template_1';

@import 'template_2';

@import 'template_3';

@import 'template_4';

@import 'template_5';