.template_boutique_2{
    .content-products {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25%;
        margin-right: -1.25%;
    }
    .produits {
        flex: 0 0 auto;
        @include elemTailleCol(2 , 1.25, true);
        position: relative;
        font-size: 1rem;
        margin-bottom: 15px;
    }
    .productImageWrap {
        position: relative;
        float: none;
        .mask {
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    .desc{
        display: none;
    }
    .prix {
        &::after {
            content: '';
            display: block;
            clear: both;
        }
        .prixprod, .oldprix {
            margin: 0 10px 10px;
            float: right;
            display: block;
            position: static;
        }
    }
    .nomprod{
        display:block;
        width:100%;
        font-size: 0;
        a{
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            font-size: .9rem;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .addbasket {
        clear: right;
        width: 50%;
        font-size: 0.8rem;
        float: right;
        box-sizing: border-box;
        margin: 10px 5px;
        text-align: center;
        padding: 5px;
    }
    .attr {
        select {
            width: 50%;
            position: relative;
            right: -50%;
            margin-top: 0;
            left: auto;
            float: none;
            display: inline-block;
        }
    }
    .hors-stock {
        box-sizing: border-box;
        float: right;
        width: auto;
        margin: 10px 5px;
        font-size: 0;
        display: block;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
    .remise span {
        font-size: 12px;
    }
}

@media #{$medium-and-down} {
    .template_boutique_2{
        .addbasket, .hors-stock {
            width: 100%;
            margin: 10px auto;
        }
    }
}

@media #{$small-and-down} {
    .template_boutique_2{
        .produits {
            @include elemTailleCol(1 , 1.25, true);
            margin-bottom: 20px;
        }
        .addbasket, .attr select {
            width: 100%;
            right: 0;
            left:0;
        }
        .zoom {
            display: none;
        }
    }
}