.template_boutique_4{
    .content-products {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25%;
        margin-right: -1.25%;
    }
    .produits {
        flex: 0 0 auto;
        @include elemTailleCol(4 , 1.25, true);
        position: relative;
        z-index:1;
        font-size: 1rem;
        padding-bottom:10px;
        margin-bottom: 15px;
        &:hover{
            .zoom{
                opacity:.2;
            }
        }
        &.is_etiquette{
            .container-etiquette{
            z-index:3;
            }
        }
    }
    .productImageWrap {
        position: static;
        overflow:visible;
        float:none;
        .mask,.zoom {
            height: 100%;
            top: 0;
            left: 0;
            z-index:1;
            &::before{
                text-align:center;
                top:0;
                padding-top:50%;
            }
        }
        img{
            position:relative;
            z-index:0;
        }
    }
    .remise span{
        font-size:12px;
    }
    .container-etiquette{
        margin-top: -5px;
        z-index:0;
    }
    .produit_etiquette{
        position: relative;
    }
    .prix {
        text-align:center;
        padding:0 10px;
        position:relative;
        z-index:2;
        .prix__block{
            justify-content: center;
        }
        .prixprod, .oldprix {
            margin: 0 auto 5px;
            font-size:1.2rem;
            display:block;
            text-align:center;
        }
        .oldprix{
            position:static;
            right:0;
            font-size:0.7rem;
        }
        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .productContentWrap{
        position:relative;
        z-index: 2;
    }
    .nomprod{
        display:block;
        width:100%;
        font-size: 0;
        margin-top:20px;
        margin-bottom:10px;
        a{
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            text-align: center;
            font-size: .9rem;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    p{
        padding:10px 10px;
    }
    .addbasket {
        clear: both;
        float:none;
        width: 80%;
        font-size: 0.8rem;
        box-sizing: border-box;
        display:block;
        margin: 10px auto;
        text-align: center;
        padding: 5px;
    }
    .attr select {
        width: 50%;
        position: relative;
        left: 25%;
        margin-top: 0;
        float:none;
    }
    .hors-stock {
        clear:both;
        float:none;
        box-sizing: border-box;
        width: auto;
        display: block;
        text-align: center;
        margin: 10px auto;
        font-size: 0;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
}

@media #{$large-and-down} {
    .template_boutique_4 .produits{
        @include elemTailleCol(3 , 1.25, true);
    }
}

@media #{$medium-and-down} {
    .template_boutique_4{
        .produits{
            @include elemTailleCol(2 , 1.25, true);
        }
        .addbasket {
            width: 100%;
            margin: 10px auto;
        }
    }
}

@media #{$small-and-down} {
    .template_boutique_4{
        .produits {
            @include elemTailleCol(1 , 1.25, true);
            margin-bottom: 20px;
        }
        .addbasket, .attr select {
            width: 100%;
            left: 0;
        }
        .zoom{
            display:none;
        }
    }
}