.template_boutique_5{

    .content-products {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25%;
        margin-right: -1.25%;
    }

    .produits {
        flex: 0 0 auto;
        @include elemTailleCol(3 , 1.25, true);
        padding: 10px;
        position:relative;
        font-size:1rem;
        margin-bottom: 15px;
        &.is_etiquette {
            .container-etiquette {
                height: auto;
            }
        }
    }
    .desc{
        display: none;
    }

    .remise{
        right:auto;
        left:10px;
        span{
            font-size:12px;
        }
    }
    .container-etiquette {
        left: 0;
        height: 0;
        position:absolute;
        margin:0;
        padding-left: inherit;
        z-index:2;
        box-sizing:border-box;
        .produit_etiquette{
            position:relative;
            h3{
                padding:0;
                white-space:normal;
            }
            &::after{
                left:50%;
                margin-left:-15px;
                z-index:50;
            }
        }
    }
    .productContentWrap{
        padding:0;
    }
    .productImageWrap {
        padding: 0;
        float: left;
        margin-top: 0;
        position:relative;
        z-index: 1;
        .mask{
            left:0;
            top:0;
            height:100%;
        }
    }
    .nomprod{
        display:block;
        font-size: 0;
        margin: 0 0 10px;
        font-weight: 700;
        padding: 5px 0;
        text-align: center;
        text-transform: uppercase;
        width:45%;
        float:right;
        a{
            font-size: 13px;
            font-weight: 700;
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .desc {
        margin: 35px 0;
    }
    .addbasket, .hors-stock {
        float: left;
        clear:both;
        position:static;
        width: 100%;
        margin: 15px auto;
        text-align:center;
    }
    .hors-stock{
        box-sizing:border-box;
        font-size: 0;
        display: block;
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
        .inside-hors-stock{
            display: inline-block;
            font-size: .7rem;
            vertical-align: middle;
        }
    }
    .attr {
        display: inline;
        width:45%;
        float:right;
        select {
            position: static;
            float: right;
            margin-bottom:10px;
            width:100%;
        }
        &::after{
            content:'';
            clear:right;
            display:block;
        }
    }
    .prix{
        position:relative;
        margin:0 0 10px;
        text-align:center;
        width:45%;
        float:right;
        .oldprix, .prixprod{
            position:static;
            display:inline;
            font-size:1.1em;
        }
        .prixprod{
            margin:0;
        }
        .oldprix{
            margin-left:15px;
            line-height:140%;
            font-size:.8em;
            margin-top:0.3em;
        }
        &::after{
            content:'';
            display:block;
            clear:both;
        }
    }

    .note-produit {
        float: right;
        width: 45%;
        > * {
            display: block;
        }
    }
}

@media #{$medium-and-up} {
    .template_boutique_5{
        .container-etiquette, .productImageWrap {
            width: 50%;
        }
    }
}

@media #{$medium-and-down}{
    .template_boutique_5{
        .produits {
            @include elemTailleCol(2 , 1.25, true);
            width: ((100%/2)-2.5);
            margin: 1.25%;
        }
        .addbasket {
            width:100%;
        }
    }
}

@media #{$small-and-down} {
    .template_boutique_5{
        .produits {
            width: 100%-2.5;
            margin: 1.25%;
            clear:both;
        }
        .container-etiquette {
            width: 100%;
            padding:0;
        }
        .zoom{
            display: none;
        }
        .remise{
            z-index:10;
        }
        .productImageWrap {
            width: 100%;
            height:100%;
            margin-bottom:15px;
            .mask{
                width:100%;
            }
        }
        .nomprod {
            margin: 10px 0;
            a{
                width:100%;
                height:100%;
                display:block;
            }
        }
        .nomprod, .prix, .attr, .note-produit{
            clear: both;
            width:100%;
            float:none;
        }
        .desc {
            margin: 5px 0;
            display: block;
        }
        .prix {
            margin-bottom: 10px;
        }
        p.attr select {
            width: 100%;
            display: block;
            position: inherit;
            float: none;
        }
        .addbasket {
            margin-top: 5px;
            position: static;
            width: 100%;
        }
        span.hors-stock{
            margin-top:10px;
            font-size: 0;
            display: block;
            &::before{
                content:'';
                display: inline-block;
                vertical-align: middle;
                height:100%;
            }
            .inside-hors-stock{
                display: inline-block;
                font-size: .7rem;
                vertical-align: middle;
            }
        }
        .productContentWrap{
            position:relative;
            float:none;
            width:100%;
            min-height:0;
            margin-bottom:0;
        }
    }
}