.template_boutique_3{
    .produits {
        width: auto;
        float: none;
        padding: 10px;
        margin-bottom: 15px;
        position:relative;
        font-size:1rem;
        &.is_etiquette {
            .container-etiquette {
                height: auto;
            }
            .nomprod {
                margin-top: -35px;
            }
        }
        &::after{
            content:'';
            display: block;
            clear:both;
        }
    }
    .remise span{
        font-size:12px;
    }
    .container-etiquette {
        left: 0;
        width: 18%;
        height: 0;
        position:relative;
        margin:0;
    }
    .produit_etiquette{
        position:initial;
        &::after{
            left:50%;
            margin-left:-15px;
            z-index:50;
        }
    }
    .productImageWrap {
        width: 18%;
        padding: 0;
        float: left;
        margin-top: 0;
        position:relative;
        .mask{
            left:0;
            top:0;
            height:100%;
        }
    }
    .nomprod{
        display:block;
        width:100%;
        font-size: 0;
        margin:0 0 10px 0;
        a{
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            font-size: .9rem;
            line-height: initial;
        }
        &::before{
            content:'';
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }
    }
    .desc {
        margin: 35px 0;
    }
    .addbasket {
        float: none;
        clear:both;
        width: 50%;
        margin: 15px auto;
        text-align:center;
        display: block;
    }
    .attr select {
        position: static;
        float: right;
        width:auto;
        min-width:20%;
        margin-bottom:10px;
    }
    .prix{
        position:relative;
        margin:0 0 10px;
        text-align:right;
        .oldprix, .prixprod{
            position:static;
            display:inline;
            float: right;
            font-size:1.1em;
        }
        .prixprod{
            margin:0;
        }
        .oldprix{
            margin-left:15px;
            line-height:140%;
            font-size:.8em;
            margin-top:0.3em;
        }
        &::after{
            content:'';
            display:block;
            clear:right;
        }
    }
    .productContentWrap{
        position:relative;
        float:right;
        width:68%;
        min-height:192px;
    }
}

@media #{$medium-and-up} {
    .template_boutique_3{
        .container-etiquette {
            width: 30%;
        }
        .productImageWrap {
            width: 30%;
        }
    }
}

@media #{$medium-and-down}{
    .template_boutique_3{
        .addbasket {
            width:100%;
        }
    }
}

@media #{$small-and-down} {
    .template_boutique_3{
        .produits {
            margin-bottom: 30px;
        }
        .zoom{
            display:none;
        }
        .container-etiquette {
            width: 100%;
        }
        .remise{
            z-index:10;
        }
        .productImageWrap {
            width: 100%;
            height:100%;
            margin-bottom:15px;
            z-index:2;
            .mask{
                width:100%;
            }
        }
        .nomprod {
            margin: 10px 0;
            clear: both;
            a{
                width:100%;
                height:100%;
                display:block;
            }
        }
        .desc {
            margin: 5px 0;
            display: block;
        }
        .prix {
            margin-bottom: 10px;
        }
        p.attr select {
            width: 100%;
            display: block;
            position: inherit;
            float: none;
        }
        .addbasket {
            margin-top: 5px;
            position: static;
            width: 100%;
            display:block;
            bottom:0;
        }
        span.hors-stock{
            margin-top:10px;
        }
        .productContentWrap{
            position:relative;
            float:none;
            width:100%;
            min-height:0;
            margin-bottom:0;
        }
    }
}